import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgxNotificationService } from "ngx-notification";
import { ApiService } from "src/app/services/api.service.js";
import { AppHelpers } from "src/app/utils/helper.js";
import { SoundMeter } from '../../../assets/js/soundmeter.js';
import { WebRTCAdaptor } from '../../../assets/js/webrtc_adaptor.js';

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { AppConfig } from "src/app/utils/config.js";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-play-stream",
  templateUrl: "play-stream.component.html",
})
export class PlayStreamComponent implements OnInit, OnDestroy {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Poppins", sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  @ViewChild('broadcastPackage') broadcastPackage;
  @ViewChild('streamName') streamName;
  @ViewChild('recordingAudio') recordingAudio;
  AppHelpers = AppHelpers;
  AppConfig = AppConfig;
  consoleLog = console.log;
  isCollapsed = true;
  selectedAudioInput = null;
  audioInputs = [];
  focus;
  focus1;
  focus2;
  streamButton = 'streamButton';
  streams = [];
  hasAutoTop = false;
  streamType = 'continuous';
  broadcastStatus = 'stopped';
  broadcastingInterval = null;
  broadcastData = null;
  webRTCAdaptor = null;
  header = null;
  stats = {
    minutesElapsed: 0,
    totalMinutes: 0,
    totalListeners: 0,
    formattedHours: 0,
    formattedMinutes: 0,
  }
  purchaseStreamRequest = {
    packageId: null,
    stripeToken: null,
  }
  streamNameRequest = {
    name: null,
  }
  packagesList = [];
  audioChunks = [];
  recordState = null;
  recordingState = "stopped";
  audioFileUrl:any = "";
  soundMeter: any = {slow:0};
  channels = 16; //default number of channels
  selectedChannel = "mix";
  constructor(private sanitizer: DomSanitizer,private _router: Router, private ngxNotificationService: NgxNotificationService, private api: ApiService, private stripeService: StripeService) {
    (async () => {
      await this.getAudioDevices();
      this.initInputStreams();
    })();

      this.header = new HttpHeaders({
        "x-access-token": AppHelpers.getToken()
      });
  
      this.getStreamData();
  
      if (localStorage.getItem('broadcasterStream') && JSON.parse(localStorage.getItem('broadcasterStream'))) {
        this.broadcastData = JSON.parse(localStorage.getItem('broadcasterStream'));
        this.hasAutoTop = this.broadcastData?.createdById?.isAutoTopupEnabled || false;
        if (!this.broadcastData.isActive) {
          this.subscribeToBroadCastPackage();
        }
      } else {
        this._router.navigate(['/stream']);
      }
      this.getBroadcasePackages();
  
  
      this.endBroadcastingAndLogOff();
  }

  async getAudioDevices() {
    let self = this;
    //required on Firefox for displaying the labels
    await navigator.mediaDevices.getUserMedia({audio: true});   
    const devices = await navigator.mediaDevices.enumerateDevices();
    self.audioInputs = devices.filter(x => x.kind === 'audioinput');
    self.selectedAudioInput = self.audioInputs[0].deviceId;

  }

  updateStreamData() {
    const { name, _id, packageId } = this.broadcastData;
    const requestBody = { _id, name, packageId }
    this.api.put('/api/stream/update', requestBody, this.header, true).subscribe(() => {
      localStorage.setItem('broadcasterStream', JSON.stringify({...this.broadcastData}));
      this.streamName.hide();
    })
  }

  getBroadcasePackages() {
    this.api.get(`/api/package/broadcaster`, null, this.header, true).subscribe(response => {
      this.packagesList = response.data;
    });
  }

  subscribeToBroadCastPackage(): void {
    if (this.purchaseStreamRequest.packageId) {
      AppHelpers.showLoader(this.streamButton);
      this.stripeService.createToken(this.card.element).subscribe((result) => {
        if (result.token) { // Use the token    
          this.purchaseStreamRequest.stripeToken = result.token.id;
          this.api.post(`/api/stream/purchaseBroadcast`, this.purchaseStreamRequest, this.header, true, false).subscribe(response => {
            this.ngxNotificationService.sendMessage('Broadcast package successfully subscribed', 'success', 'bottom-left');
            AppHelpers.hideLoader(this.streamButton);
            this.broadcastPackage.hide();
            this.card.element.clear();
            window.location.reload();
          }, () => {
            this.ngxNotificationService.sendMessage("Payment couldn't be processed, please try again later or contact administrator.", 'danger', 'bottom-left');
            AppHelpers.hideLoader(this.streamButton);
          });
        } else if (result.error) {
          this.ngxNotificationService.sendMessage(result.error.message, 'danger', 'bottom-left');
          AppHelpers.hideLoader(this.streamButton);
        }
      }, () => {
        this.ngxNotificationService.sendMessage("Payment couldn't be processed, please try again later or contact administrator.", 'danger', 'bottom-left');
        AppHelpers.hideLoader(this.streamButton);
      });
    }
  }

  scrollToDownload(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  updateLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const requestBody = { streamId: this.broadcastData._id, latitude: position.coords.latitude, longitude: position.coords.longitude };
        this.api.put('/api/stream/update/location', requestBody, this.header, true).subscribe(() => {
          console.log("Location Updated");
        })
      }, (error) => console.warn(error), { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 });
    } else {
      alert("Geolocation is not supported by this browser.");
    } 
  }

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("index-page");

    // this.updateLocation();
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("index-page");

    this.stopBroadcasting();
  }

  streamChanged(newStreamType) {
    this.streamType = newStreamType;
    if (newStreamType === 'push-to-talk') {
      this.updateBroadcasting('stopped');
    }

    clearTimeout(this.broadcastingInterval);
    this.endBroadcastingAndLogOff();
  }

  showLoader() {
    if (this.streamType != 'push-to-talk') {
      document.querySelector('.page-loader').classList.add('loader--active');
    }
  }

  hideLoading() {
    document.querySelector('.page-loader').classList.remove('loader--active');
  }

  muteBroadcasting() {
    // AppConfig.webRTCAdaptor.publish(this.broadcastData.streamId, undefined, undefined, undefined, this.broadcastData.name);
    // AppConfig.webRTCAdaptor.enableStats(this.broadcastData.streamId);
    AppConfig.webRTCAdaptor.toggleAudio(this.broadcastData.streamId, null, false);
    AppConfig.webRTCAdaptor.muteLocalMic();
    if (AppConfig.broadcastStatus !== "disconnected") {
      AppConfig.broadcastStatus = 'broadcasting_stopped';
    }

    clearTimeout(this.broadcastingInterval);
    this.endBroadcastingAndLogOff();
  }

  unmuteBroadcasting() {
    // AppConfig.webRTCAdaptor.stop(this.broadcastData.streamId);
    AppConfig.webRTCAdaptor.toggleAudio(this.broadcastData.streamId, null, true);
    AppConfig.webRTCAdaptor.unmuteLocalMic();
    AppConfig.broadcastStatus = 'broadcasting_started';
    // AppConfig.broadcastStatus = 'connected';
  }

  publishBroadcasting() {
    // AppConfig.webRTCAdaptor.play(this.broadcastData.streamId);
    // AppConfig.broadcastStatus = 'published';
    // AppConfig.webRTCAdaptor.enableStats(this.broadcastData.streamId);
    AppConfig.webRTCAdaptor.toggleAudio(this.broadcastData.streamId, null, true)
  }

  unpublishBroadcasting() {
    AppConfig.webRTCAdaptor.stop(this.broadcastData.streamId);
    AppConfig.broadcastStatus = 'disconnected';
    // AppConfig.webRTCAdaptor.enableStats(this.broadcastData.streamId);
    // AppConfig.webRTCAdaptor.toggleAudio(this.broadcastData.streamId, null, false)
  }
  
  stopBroadcasting(status = 'disconnected') {
    AppConfig.webRTCAdaptor.stop(this.broadcastData.streamId);
    // AppConfig.webRTCAdaptor.enableStats(this.broadcastData.streamId);
    // AppConfig.webRTCAdaptor.toggleAudio(this.broadcastData.streamId, null, false)
  }

  updateBroadcasting(status: string) {
    if ((AppConfig.broadcastStats.totalMinutes - AppConfig.broadcastStats.minutesElapsed) <= 0 && !this.hasAutoTop) {
      this.stopBroadcasting('limit_reached');
      AppConfig.webRTCAdaptor.closeStream(this.broadcastData.streamId);
      AppConfig.webRTCAdaptor.closeWebSocket(this.broadcastData.streamId);
    }
    switch (status) {
      case 'started':
        this.unmuteBroadcasting();
        break;
      case 'stopped':
        this.muteBroadcasting();
        break;
      case 'publish':
        this.muteBroadcasting();
        break;
      case 'unpublish':
        this.unmuteBroadcasting();
        break;
    }
  }

  initRecorder(stream : any = null) {
    if(!stream){
      navigator.mediaDevices.getUserMedia({ audio: {deviceId: this.selectedAudioInput ? {exact: this.selectedAudioInput} : undefined} }).then(stream => {
        this.handlerFunction(stream);
      });
    }
    else{
      this.handlerFunction(stream);
    }
  }

  handlerFunction(stream) {
    if(this.recordingState == "recording"){
      this.stopRecord(null);
    }
    this.recordState = new window["MediaRecorder"](stream);
    this.recordState.ondataavailable = e => {
      this.audioChunks.push(e.data);
      if (this.recordState.state == "inactive") {
        let blob = new Blob(this.audioChunks, { type: 'audio/aac' });
        const sanitizeURL =  this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
        this.audioFileUrl = sanitizeURL;
        // this.recordingAudio.nativeElement.src = URL.createObjectURL(blob);
        // this.recordingAudio.nativeElement.controls = true;
        // this.recordingAudio.nativeElement.autoplay = true;
        this.sendData(blob)
        let file = new File([blob as BlobPart], "welcome.aac");
        console.log(file, blob);
        const formData = new FormData();
        formData.append('file', file);
        this.uploadAudioWelcome(formData);
      }
      
    }
  }

  uploadFile(event) {
    console.log(event, "Event");
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    this.uploadAudioWelcome(formData);
  }

  getStreamData () {
    this.api.get('/api/stream/broadcasterStream', null, this.header, true).subscribe((res) => {
      if (res.data) {
        localStorage.setItem('broadcasterStream', JSON.stringify(res.data));

        this.broadcastData = res.data;
        var broadcastData = this.broadcastData;
        this.hasAutoTop = this.broadcastData?.createdById?.isAutoTopupEnabled || false;
        AppConfig.hasAutoTopUp = this.hasAutoTop;
      }
    })
  }

  uploadAudioWelcome(formData) {
    this.ngxNotificationService.sendMessage('Welcome message is uploading...', 'info', 'bottom-left');
    this.api.post('/api/stream/upload', formData, this.header, true).subscribe((res) => {
      
      const { name, packageId, _id } = this.broadcastData;
      const requestBody = { _id, name, packageId, welcomeAudioUrl: res.data }

      this.api.put('/api/stream/update', requestBody, this.header, true).subscribe(() => {
        localStorage.setItem('broadcasterStream', JSON.stringify({...this.broadcastData, welcomeAudioUrl: res.data}));

        this.broadcastData.welcomeAudioUrl = res.data;

        this.ngxNotificationService.sendMessage(res.message, 'success', 'bottom-left');
      })

    }, (err) => {
      this.ngxNotificationService.sendMessage(err.message, 'warning', 'bottom-left');
    })
  }

  sendData(data) { }

  initRecord(e) {
    this.recordingState = "recording";
    this.audioChunks = [];
    this.recordState.start();
  }

  stopRecord(e) {
    this.recordingState = "stopped";
    this.audioChunks = [];
    this.recordState.stop();
  }

  changeAutoTopUp() {
    let message = this.hasAutoTop ? "disabled" : "enable";
    if (confirm(`Are you sure you want to ${message} Auto-Top up`)) {
      let requestBody = {
        ...this.broadcastData,
        isAutoTopupEnabled: !this.hasAutoTop,
      };
      this.api.put('/api/user/profile', requestBody, this.header, true).subscribe((res) => {
        if (res.data) {
          this.ngxNotificationService.sendMessage(`Auto top up ${message}`, 'success', 'bottom-left');
          this.hasAutoTop = !this.hasAutoTop;
          this.broadcastData.isAutoTopupEnabled = res.data.isAutoTopupEnabled;
          localStorage.setItem('riotUserInfo', JSON.stringify({...res.data, isAutoTopupEnabled: !this.hasAutoTop}));
        }
      })
    }
  }

  endBroadcastingAndLogOff() {
    let timeout = this.streamType == 'continuous' ? (43200 * 1000) : (3600 * 1000) ;
    this.broadcastingInterval = setTimeout(() => {
      this.stopBroadcasting();
      AppHelpers.destroyToken();
      AppHelpers.destroyUserInfo();
      window.location.reload();
    }, timeout);
  }

  formatNumber(number) {
    return new Intl.NumberFormat('en').format(number);
  }

  initInputStreams(channel = "") {
    const options = {
      audio: {
        deviceId: this.selectedAudioInput
          ? { exact: this.selectedAudioInput }
          : undefined,
          echoCancellation: channel ? false:true,
      },
    };
    
    navigator.mediaDevices.getUserMedia(options).then((stream) => {
      if(channel){
        stream = this.splitChannels(stream, channel);
      }
      this.initSoundMeter(stream);
      this.initRecorder(stream);

      this.AppConfig.webRTCAdaptor.updateAudioTrack(
        stream.clone(),
        this.broadcastData.streamId,
        null
      );
    });
  }

  initSoundMeter(stream) {
    const audioCtx = new window.AudioContext();
    this.soundMeter = new SoundMeter(audioCtx);
    this.reloadSoundMeter(stream);
    setInterval(() => {
      this.soundMeter.update();
    }, 20);
  }

  reloadSoundMeter(stream) {
    this.soundMeter.disconnectCurentSource();
    this.soundMeter.connectToSource(stream);
  }

  splitChannels(stream, channel) {
    const audioCtx = new AudioContext();
    const input = audioCtx.createMediaStreamSource(stream)
    const splitter = audioCtx.createChannelSplitter(parseInt(channel));
    const monoSource = audioCtx.createMediaStreamDestination();
    input.connect(splitter);
    splitter.connect(monoSource, parseInt(channel)-1, 0);
    return monoSource.stream;
  }

  selectionChanged() {
    if (this.selectedChannel != "mix") {
      this.initInputStreams(this.selectedChannel);
    } else {
      this.initInputStreams();
    }
  }
}