import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { IndexComponent } from "./pages/index/index.component";
import { ProfilepageComponent } from "./pages/examples/profilepage/profilepage.component";
import { RegisterpageComponent } from "./pages/examples/registerpage/registerpage.component";
import { LandingpageComponent } from "./pages/examples/landingpage/landingpage.component";

// App Components
import { SplashComponent } from "./pages/home/home.component";
import { LoginComponent } from "./pages/login/login.component";
import { RegisterComponent } from "./pages/register/register.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { StreamComponent } from "./pages/stream/stream.component";
import { AccountDetailsComponent } from "./pages/account-details/account-details.component";
import { TermsConditionsComponent } from "./pages/terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { PlayStreamComponent } from "./pages/play-stream/play-stream.component";
import { AuthGuard } from "./guards/auth-guard.service";
import { AuthenticatedGuard } from "./guards/authenticated-guard.service";
import { NgxNotificationComponent } from "ngx-notification";

const routes: Routes = [
  // { path: "home-2", component: IndexComponent, canActivate: [AuthGuard] },
  // { path: "profile", component: ProfilepageComponent },
  // { path: "landing", component: LandingpageComponent },

  // { path: "home", component: SplashComponent },
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "stream", component: StreamComponent, canActivate: [AuthGuard] },
  {
    path: "account-details",
    component: AccountDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "terms-and-conditions",
    component: TermsConditionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "play-stream",
    component: PlayStreamComponent,
    canActivate: [AuthGuard],
  },
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "**", redirectTo: "/login" },
  // { path: "", redirectTo: "/play-stream", pathMatch: "full" },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [],
})
export class AppRoutingModule {}
