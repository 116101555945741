import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";

@Component({
  selector: "app-forgot-password",
  templateUrl: "forgot-password.component.html"
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  focus;
  focus1;
  focus2;
  formButton = 'formButton';
  formError = false;
  constructor() {}
  
  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("register-page");
  }

  submitForm() {
    document.querySelector(`#${this.formButton}`).classList.add('is--loading');
    setTimeout(() => {
      this.formError = true;
      document.querySelector(`#${this.formButton}`).classList.remove('is--loading');
    }, 5000);
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("register-page");
  }
}
