import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import noUiSlider from "nouislider";

@Component({
  selector: "app-terms-conditions",
  templateUrl: "terms-conditions.component.html"
})
export class TermsConditionsComponent implements OnInit, OnDestroy {
  constructor() {}
  ngOnInit() {
    
  }
  ngOnDestroy() {
  }
}