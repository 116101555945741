import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { ModalModule } from "ngx-bootstrap/modal";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { PopoverModule } from "ngx-bootstrap/popover";
import { NgxStripeModule } from 'ngx-stripe';
import { NgxNotificationComponent, NgxNotificationService, NgxNotificationModule } from 'ngx-notification';

import { IndexComponent } from "./index/index.component";
import { ProfilepageComponent } from "./examples/profilepage/profilepage.component";
import { RegisterpageComponent } from "./examples/registerpage/registerpage.component";
import { LandingpageComponent } from "./examples/landingpage/landingpage.component";

// AppComponent
import { SplashComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { StreamComponent } from "./stream/stream.component";
import { AccountDetailsComponent } from "./account-details/account-details.component";
import { TermsConditionsComponent } from "./terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { PlayStreamComponent } from "./play-stream/play-stream.component";
import { HeaderNavbarComponent } from "../shared/partials/navbar/navbar.component";
import { environment } from "src/environments/environment";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    NgxStripeModule.forRoot(environment.STRIPE_KEY),
    NgxNotificationModule,
  ],
  declarations: [
    SplashComponent,
    IndexComponent,
    ProfilepageComponent,
    RegisterpageComponent,
    LandingpageComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    StreamComponent,
    AccountDetailsComponent,
    HeaderNavbarComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    PlayStreamComponent,
  ],
  exports: [
    SplashComponent,
    IndexComponent,
    ProfilepageComponent,
    RegisterpageComponent,
    LandingpageComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    StreamComponent,
    AccountDetailsComponent,
    HeaderNavbarComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    PlayStreamComponent,
    NgxNotificationComponent,
  ],
  providers: [
    NgxNotificationService,
  ]
})
export class PagesModule {}
