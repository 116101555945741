import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NgxNotificationService } from "ngx-notification";
import noUiSlider from "nouislider";
import { ApiService } from "src/app/services/api.service";
import { AppHelpers } from "src/app/utils/helper";
import LocationPicker from "location-picker";

@Component({
  selector: "app-account-details",
  templateUrl: "account-details.component.html"
})
export class AccountDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('myModal2') myModal2;
  lp: LocationPicker;
  isCollapsed = true;
  focus;
  focus1;
  focus2;
  submitError = false;
  date = new Date();
  pagination = 3;
  pagination1 = 1;
  accountSubmitButton = 'accountSubmitButton';
  locationSubmitButton = 'locationSubmitButton';
  accountSubmitPasswordButton = 'accountSubmitPasswordButton';
  accountSubmitHyperlinkButton = 'accountSubmitHyperlinkButton';
  currentContentView = 'streams';
  focusCollection = {};
  streams = [];
  payments = [];
  userImage = null;
  requestBody: any = {};
  changePasswordBody: any = {};
  hyperlinkBody: any = {
    isHyperLinkVisible: false,
  };
  header = null;
  broadcastStreamData: any = {};
  packagesList = [];
  packagesBroadcasterList = [];
  AppHelpers = AppHelpers;
  constructor(private _router: Router, private sanitizer: DomSanitizer, private ngxNotificationService: NgxNotificationService, private api: ApiService) {
    this.header = new HttpHeaders({
      "x-access-token": AppHelpers.getToken()
     });

     this.getProfileData();
     this.getPaymentData();
     this.getPackagesData();
     this.getStreamData();
     this.getBroadcasePackages();
    }
    
  ngOnInit() {}
  ngOnDestroy() {}

  initializeMap() {
    let setCurrentPosition = true, lat = undefined, lng = undefined;
    const { location } = this.broadcastStreamData;
    if (location.coordinates[1] && location.coordinates[0]) {
      setCurrentPosition = false;
      lat = location.coordinates[1];
      lng = location.coordinates[0];
    }
    this.lp = new LocationPicker('map', { setCurrentPosition, lat, lng }, { disableDefaultUI: true, zoomControl: true, zoom: 20 });
  }

  

  getProfileData () {
    this.api.get('/api/user/profile', null, this.header, true).subscribe((res) => {
      if (res.data) {
        this.requestBody = {...res.data};
      }
    })
  }
  
  getStreamData () {
    this.api.get('/api/stream/broadcasterStream', null, this.header, true).subscribe((res) => {
      if (res.data) {
        localStorage.setItem('broadcasterStream', JSON.stringify(res.data));

        this.broadcastStreamData = res.data;
        this.hyperlinkBody = {
          hyperLinkText: this.broadcastStreamData.hyperLinkText, 
          hyperLinkUrl: this.broadcastStreamData.hyperLinkUrl,
          isHyperLinkVisible: this.broadcastStreamData.isHyperLinkVisible ? true : false,
        }

        this.initializeMap();
      }
    })
  }

  getPackagesData () {
    this.api.get('/api/package/listener', null, this.header, true).subscribe((res) => {
      if (res.data) {
        this.packagesList = res.data.map(x => { return {...x, tier: x.name.split('Tier')[1]} });
      }
    })
  }

  updateStreamData() {
    const { name, packageId, _id, locationRestriction, isPremium } = this.broadcastStreamData;
    const requestBody = { _id, name, packageId, locationRestriction, isPremium }
    this.api.put('/api/stream/update', requestBody, this.header, true).subscribe(() => {
      localStorage.setItem('broadcasterStream', JSON.stringify({...this.broadcastStreamData}));
    })
  }

  updateStreamLocation() {
    const { _id, location } = this.broadcastStreamData;
    const requestBody = { streamId: _id, latitude: location.coordinates[1], longitude: location.coordinates[0] };
    this.api.put('/api/stream/update/location', requestBody, this.header, true).subscribe(() => {
      AppHelpers.hideLoader(this.locationSubmitButton);
      console.log("Location Updated");
    });
  }

  getPaymentData () {
    this.api.get('/api/user/paymentHistory', null, this.header, true).subscribe((res) => {
      if (res.data) {
        console.log(res.data);
        this.payments = res.data;
        // this.requestBody = {...res.data};
      }
    })
  }

  getBroadcasePackages() {
    this.api.get(`/api/package/broadcaster`, null, this.header, true).subscribe(response => {
      this.packagesBroadcasterList = response.data;
    });
  }

  submit() {
    if (this.requestBody['name']) {
      AppHelpers.showLoader(this.accountSubmitButton);
      this.api.put('/api/user/profile', this.requestBody, this.header, true).subscribe((res) => {
        if (res.data) {
          this.ngxNotificationService.sendMessage('Profile succesfully updated', 'success', 'bottom-left');
          AppHelpers.hideLoader(this.accountSubmitButton);
        }
      })
    } else {
      this.ngxNotificationService.sendMessage('Name field is required', 'warning', 'bottom-left');
    }
  }
  submitPassword() {
    if (this.changePasswordBody['oldPassword'] && this.changePasswordBody['newPassword'] == this.changePasswordBody['confirmPassword']) {
      AppHelpers.showLoader(this.accountSubmitPasswordButton);
      this.api.put('/api/user/resetPassword', this.changePasswordBody, this.header, true).subscribe((res) => {
        if (res.data) {
        }
        this.ngxNotificationService.sendMessage(res.message, 'success', 'bottom-left');
        AppHelpers.hideLoader(this.accountSubmitPasswordButton);
      })
    } else {
      this.ngxNotificationService.sendMessage('Please provide valid passwords', 'warning', 'bottom-left');
    }
  }
  submitHyperlink() {
    if (this.hyperlinkBody['hyperLinkText'] && this.hyperlinkBody['hyperLinkUrl']) {
      AppHelpers.showLoader(this.accountSubmitHyperlinkButton);
      this.hyperlinkBody.streamId = this.broadcastStreamData._id;
      this.api.put('/api/stream/update/hyperlink', this.hyperlinkBody, this.header, true).subscribe((res) => {
        this.ngxNotificationService.sendMessage("Hyperlink settings have been saved", 'success', 'bottom-left');
        AppHelpers.hideLoader(this.accountSubmitHyperlinkButton);
      })
    } else {
      this.ngxNotificationService.sendMessage('Request couldn\'t be sent', 'warning', 'bottom-left');
    }
  }

  getPaymentPrice(message) {
    return this.packagesBroadcasterList.find(x => x.name == message.split(' package')[0])?.price;
  }

  uploadImage(event) {
    const file = event.target.files[0];
    const fileURL = URL.createObjectURL(file);
    const sanitizeURL =  this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
    this.userImage = sanitizeURL;

    const formData = new FormData();
    formData.append('file', file);

    this.api.post('/api/stream/upload', formData, this.header, true).subscribe((res) => {
      
      const { name, packageId, _id } = this.broadcastStreamData;
      const requestBody = { _id, name, packageId, pictureUrl: res.data }

      this.api.put('/api/stream/update', requestBody, this.header, true).subscribe(() => {
        localStorage.setItem('broadcasterStream', JSON.stringify({...this.broadcastStreamData, pictureUrl: res.data}));

        this.ngxNotificationService.sendMessage(res.message, 'success', 'bottom-left');
      })

    }, (err) => {
      this.ngxNotificationService.sendMessage(err.message, 'warning', 'bottom-left');
    })
  }

  onhyperLinkChange(ev) {
    this.hyperlinkBody['isHyperLinkVisible'] = ev.currentValue;
  }
  
  onPremiumChange(ev) {
    this.broadcastStreamData['isPremium'] = ev.currentValue;
  }
  
  onLocationRestrictionChange(ev) {
    this.broadcastStreamData['locationRestriction'] = ev.currentValue;
  }

  submitLocation() {
    let submitRequest = false;
    const location = this.lp.getMarkerPosition();
    this.broadcastStreamData['location']['coordinates'][0] = location.lng;
    this.broadcastStreamData['location']['coordinates'][1] = location.lat;
    if (this.broadcastStreamData['location']?.['coordinates']?.[0] && this.broadcastStreamData['location']?.['coordinates']?.[1] && this.broadcastStreamData['locationRestriction']) {
      submitRequest = true;
    } else if (!this.broadcastStreamData['locationRestriction']) {
      submitRequest = true;
    }

    if (submitRequest) {
      AppHelpers.showLoader(this.locationSubmitButton);
      this.updateStreamData();
      this.updateStreamLocation();
    } else {
      this.ngxNotificationService.sendMessage("Latitude and Longitude are required!", 'warning', 'bottom-left');
    }
    
  }
}