// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: `https://api.riotbroadcast.com`,
  STRIPE_KEY:
    "pk_test_51JTQjZIZrQ1ZHdcIJkFrdWXZ0PMaYBw8z4XfZZHW3PAaWbxpTmGCMJbiCEU6EXFYoBU4RyPXpPcf6g7F0UFQrAN200J7ISsSCF",
  // STRIPE_KEY: 'pk_live_51JTQjZIZrQ1ZHdcI2GiDQvi42KZ51K4stsNUdZmTWOzjNFxQEFu0bMrW1QVEcFaT63VEGDnjG2w41Mz1Uhz14MND00FmbX70Jt'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
