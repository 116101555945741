import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import noUiSlider from "nouislider";
import { AppHelpers } from "src/app/utils/helper";
import { ApiService } from "src/app/services/api.service.js";

import { WebRTCAdaptor } from '../../../../assets/js/webrtc_adaptor.js';
import { NgxNotificationService } from "ngx-notification";
import { HttpHeaders } from "@angular/common/http";
import { AppConfig } from "src/app/utils/config.js";

@Component({
  selector: "app-header-nav",
  templateUrl: "navbar.component.html"
})
export class HeaderNavbarComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  streamURL = ['/play-stream'];
  broadcastStatus = 'stopped';
  broadcastData = null;
  streamToken = "";
  publishInterval = null;
  header = null;
  antHeaders = null;
  constructor(private _router: Router, private api: ApiService, private ngxNotificationService: NgxNotificationService,) {
    if (localStorage.getItem('broadcasterStream') && JSON.parse(localStorage.getItem('broadcasterStream'))) {
      this.broadcastData = JSON.parse(localStorage.getItem('broadcasterStream'));
      this.streamURL = ['/play-stream'];
    } else {
      this.streamURL = ['/stream'];
    }

    this.header = new HttpHeaders({
      "x-access-token": AppHelpers.getToken(),
     });

     this.antHeaders = new HttpHeaders({
      "Authorization": AppHelpers.getToken(),
     });

    if (AppHelpers.isLoggedIn() && this.broadcastData && !AppConfig.webRTCAdaptor) {
      this.configureBroadCasting();
      this.getStreamStats(true)
    }
  }
  ngOnInit() {
    this.publishStreamToken();
  }
  ngOnDestroy() {
  }

  logout() {
    this.api.post('/api/user/logout', {}, this.header, true).subscribe(() => {
      AppHelpers.destroyToken();
      AppHelpers.destroyUserInfo();
      localStorage.removeItem('broadcasterStream');
      this._router.navigate(['/login']);
      window.location.reload();
      AppConfig.webRTCAdaptor.closeStream(this.broadcastData.streamId);
      AppConfig.webRTCAdaptor.closeWebSocket(this.broadcastData.streamId);
      clearInterval(this.publishInterval);
    })
  }

  getStreamStats(isReloaded = false) {
    this.api.get('a', null, this.antHeaders, true, false, `https://dev-alb.riotbroadcast.com/RIOT/rest/v2/broadcasts/${this.broadcastData.streamId}`).subscribe((res) => {
      console.log("Count", res);
      AppConfig.broadcastStats.totalListeners = res.webRTCViewerCount;
    })
    this.api.get(`/api/stream/stats/id/${this.broadcastData.id}`, null, this.header, true).subscribe(response => {
      if (response.success && response.data != null) {
        AppConfig.broadcastStats.minutesElapsed = response.data.minutesElapsed || 0;
        // AppConfig.broadcastStats.totalListeners = response.data.totalListeners;
        AppConfig.broadcastStats.totalMinutes = response.data.totalMinutes || 0;
        AppConfig.hasAutoTopUp = response.data?.isAutoTopupEnabled || false;

        const analytics = AppHelpers.streamAnalytics(AppConfig.broadcastStats.minutesElapsed, AppConfig.broadcastStats.totalMinutes, AppConfig.broadcastStats.totalListeners)

        AppConfig.broadcastStats.formattedHours = analytics.hour;
        AppConfig.broadcastStats.formattedMinutes = analytics.minute;

        if ((AppConfig.broadcastStats.totalMinutes - AppConfig.broadcastStats.minutesElapsed) <= 0 && !AppConfig.hasAutoTopUp) {
          AppConfig.broadcastStatus = 'limit_reached';
          if (!isReloaded) {
            window.location.reload();
          }
        } else {
          AppConfig.broadcastStatus = AppConfig.broadcastStatus == "limit_reached" ? "connected" : AppConfig.broadcastStatus;
        }
      }
    })
  }

  publishStreamToken() {
    return this.api.post(`/api/stream/publishToken`, {streamId: this.broadcastData.streamId}, this.header, true);
  }

  configureBroadCasting() {
    let self = this;
    let pc_config = {
      iceServers: [{
        urls: "turn:turn.riotbroadcast.com:3478",
        username: "riotbroadcast",
        credential: "wormy12345"
      }]
    };

    let sdpConstraints = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: false

    };
    let mediaConstraints = {
      audio: true
    };

    AppConfig.webRTCAdaptor = new WebRTCAdaptor({
      bandwidth: 16,
      // websocket_url: "ws://" + location.hostname + ":" + location.port + "/WebRTCAppEE",
      websocket_url: "wss://dev-alb.riotbroadcast.com/RIOT/websocket",
      mediaConstraints: mediaConstraints,
      peerconnection_config: pc_config,
      sdp_constraints: sdpConstraints,
      callback: function (info, data) {
        if (info == "initialized") {
          console.log("initialized", data, AppConfig.webRTCAdaptor);
          self.publishStreamToken().subscribe(response => {
            if (response.success && response.data != null) {
              localStorage.setItem('broadcasterToken', response.data);
              // Check if user has minutes or hours, then only start the stream.
              if ( (AppConfig.broadcastStats.totalMinutes - AppConfig.broadcastStats.minutesElapsed) > 0 ) {
                self.streamToken = response.data;
                AppConfig.webRTCAdaptor.publish(self.broadcastData.streamId, response.data, undefined, undefined, self.broadcastData.name);
                // AppConfig.webRTCAdaptor.toggleAudio(self.broadcastData.streamId, null, false);
                // AppConfig.webRTCAdaptor.muteLocalMic();
                AppConfig.webRTCAdaptor.enableStats(self.broadcastData.streamId);
              }
            }
          });
        }
        else if (["publish_started", "session_restored"].some(val => val == info)) {
          console.log("publish started");
          if (AppConfig.broadcastStatus != 'limit_reached') {
            // AppConfig.broadcastStatus = "connected";
            AppConfig.broadcastStatus = "broadcasting_started";
          }
          AppConfig.webRTCAdaptor.toggleAudio(self.broadcastData.streamId, null, true);
          AppConfig.webRTCAdaptor.unmuteLocalMic();
          clearInterval(self.publishInterval);
        }
        else if (info == "publish_finished") {
          console.log("publish finished");
          AppConfig.broadcastStatus = "disconnected";

          clearInterval(self.publishInterval);

          self.publishInterval = setInterval(() => {
            AppConfig.webRTCAdaptor.publish(self.broadcastData.streamId, self.streamToken, undefined, undefined, self.broadcastData.name);
          }, 10000)
        }
        else if (info == "updated_stats") {
          //"Stop Sharing" is clicked in chrome screen share dialog
          console.log("updated_stats", info, data);
          self.getStreamStats();
        }

      },
      callbackError: function (error) {
        //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        clearInterval(self.publishInterval);
        self.publishInterval = setInterval(() => {
          AppConfig.webRTCAdaptor.publish(self.broadcastData.streamId, self.streamToken, undefined, undefined, self.broadcastData.name);
        }, 10000)

        console.log("error callback: " + error);
        // alert(error);
        self.ngxNotificationService.sendMessage(error, 'danger', 'bottom-left');
      }
    });
  }
}