import { Component, OnInit, OnDestroy, HostListener, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { AppHelpers } from "src/app/utils/helper";
import { NgxNotificationService } from 'ngx-notification';
import { HttpHeaders } from "@angular/common/http";
import LocationPicker from "location-picker";

@Component({
  selector: "app-login",
  templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('termsModal') termsModal;
  @ViewChild('locationModal') locationModal;
  isCollapsed = true;
  lp: LocationPicker;
  focus;
  focus1;
  focus2;
  loginButton = 'loginButton';
  loginError = false;
  requestBody: any = {
    username: null,
    password: null,
  }
  redirectTo = ['/play-stream'];
  broadcastData = null;
  newLocation = null;
  header = null;
  constructor(private _router: Router, private api: ApiService, private ngxNotificationService: NgxNotificationService) {}
  
  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("register-page");
  }

  updateStreamLocation() {
    const { _id, location } = this.broadcastData;
    const requestBody = { streamId: _id, latitude: location.coordinates[1], longitude: location.coordinates[0] };
    this.api.put('/api/stream/update/location', requestBody, this.header, true).subscribe(() => {
      this.locationModal.hide();
      this._router.navigate(this.redirectTo);
    });
  }

  initializeMap() {
    let setCurrentPosition = true;
    this.lp = new LocationPicker('map', { setCurrentPosition }, { disableDefaultUI: true, zoomControl: true, zoom: 20 });
    this.newLocation = this.lp.getMarkerPosition();
    this.lp.map.addListener('dragend', () => {
      this.newLocation = this.lp.getMarkerPosition();
    });
  }

  saveLocation() {
    const newLocation = this.lp.getMarkerPosition();
    console.log(newLocation);
    this.broadcastData['location']['coordinates'][0] = newLocation.lng;
    this.broadcastData['location']['coordinates'][1] = newLocation.lat;

    this.updateStreamLocation();
  }

  closeLocationPopup() {
    this.locationModal.hide(); 
    this._router.navigate(this.redirectTo);
  }

  acceptTerms() {
    this.api.put('/api/user/verifyTerms', {hasAcceptedTerms: true}, this.header, false).subscribe((res) => {
      this.termsModal.hide();
      this.initializeMap(); 
      this.locationModal.show();
    })
  }

  submitLogin() {
    this.requestBody.userType = 1;

    if (this.requestBody.username && this.requestBody.password) {
      AppHelpers.showLoader(this.loginButton);
      this.api.post('/api/user/login', this.requestBody, {}, false).subscribe((res) => {
        if (res.data) {
          // If a session was active, remove the credentials
          if(AppHelpers.isLoggedIn()){
            AppHelpers.destroyToken();
            AppHelpers.destroyUserInfo();
            localStorage.removeItem('broadcasterStream');
          }
          this.ngxNotificationService.sendMessage('User successfully Logged in!', 'success', 'bottom-left');
          // Configure User Information
          AppHelpers.saveToken(res.data.token);
          AppHelpers.saveUserInfo(res.data);

          // let header = {'x-access-token': res.data.token};
          this.header = new HttpHeaders({
            "x-access-token": res.data.token 
           });
          this.api.get('/api/stream/broadcasterStream', null, this.header, false).subscribe(response => {
            if (response.success && response.data == null) {
              this.redirectTo = ['/stream'];
            } else {
              localStorage.setItem('broadcasterStream', JSON.stringify(response.data));
            }

            this.broadcastData = response.data;
            
            AppHelpers.hideLoader(this.loginButton);
            if (!res.data.hasAcceptedTerms) {
              this.termsModal.show();
            } else {
              this.initializeMap(); 
              this.locationModal.show();
            }
          })

          delete res.data.token;
        }
      })
    } else{
      this.ngxNotificationService.sendMessage('Form must be validated in order to send request.', 'warning', 'bottom-left');
    }
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("register-page");
  }
}
