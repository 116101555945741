import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppHelpers } from '../utils/helper';
import { AppConfig } from '../utils/config';

@Injectable()
export class AuthGuard implements CanActivate {
  private canAccess = false;
  constructor(
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  // ): Observable<boolean> {
  ): boolean {
    if (!AppHelpers.isLoggedIn() || !AppHelpers.getUserInfo()) {
      AppHelpers.destroyUserInfo();
      AppHelpers.destroyToken();
      this.router.navigate(['login']);
      this.canAccess = false
    } else {
      this.canAccess = true;
    }
    return this.canAccess;
  }
}
