// Class Import
import { AppConfig } from './config';

// Plugins
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { NgxNotificationService } from 'ngx-notification';

@Injectable()
export class AppHelpers {
  constructor(private ngxNotificationService: NgxNotificationService) {
  }

  /**
   * @description
   * Formats the given date in the given format
   *
   * @params
   * date: Date
   * format: Desired format for date
   */ 
  public static formatDate(date, format: string): any{
    return moment(new Date(date).getTime()).format(format);
  }

  public static isValid(value: string, regExpression = AppConfig.RegexPatterns.password) {
    return new RegExp(regExpression).test(value);

  }

  /**
   * @description
   * Show Notification Bar
   *
   * @params
   * type: string, title: string, message: string, icon: string
   */
  public static showNotification(type = 'success', title = '', message: string, icon = '', extraClass = '') {
    
  }

  public static getToken(): string {
    if (AppConfig.loginToken) {
      return AppConfig.loginToken;
    }
    return this.getCookie('riotToken');
  }

  public static getUserInfo() {
    return this.getCookie('riotUserInfo') ? JSON.parse(this.getCookie('riotUserInfo')) : null;
  }

  public static saveToken(token: string) {
    AppConfig.loginToken = token;
    AppConfig.isLoggedIn = true;
    this.setCookie('riotToken', token);
  }

  public static hoursRemaining(elapsed: any = 0, minutesTotal: any = 0) {
    let minutesRemaining = minutesTotal - elapsed;
    let hours = (minutesRemaining/60).toFixed(2);
    return hours;
  }

  public static streamAnalytics(elapsed= 0, minutesTotal= 0, totalListeners = 0) {
    let minutesRemaining = (minutesTotal - elapsed) >= 0 ? (minutesTotal - elapsed) : 0 ;
    let hour = (minutesRemaining / 60).toFixed(0);
    let minute = totalListeners ? (minutesRemaining / totalListeners).toFixed(0) : "∞"; //minutesRemaining.toString(); //(minutesRemaining / 60 % 60).toFixed(0);
    // return formated string
    return {hour, minute};
  }

  public static saveUserInfo(userInfo) {
    this.setCookie('riotUserInfo', JSON.stringify(userInfo));
  }

  public static destroyToken() {
    AppConfig.loginToken = null;
    AppConfig.isLoggedIn = false;
    this.deleteCookie('riotToken');
  }

  public static destroyUserInfo() {
    this.deleteCookie('riotUserInfo');
    this.deleteCookie('broadcasterStream');
  }

  public static isLoggedIn() {
    return this.getToken() ? true : false;
  }

  public static showLoader(elementId) {
    document.getElementById(elementId).classList.add('is--loading');
  }

  public static hideLoader(elementId = null) {
    if (elementId) {
      document.getElementById(elementId) ? document.getElementById(elementId).classList.remove('is--loading') : null;
    } else {
      document.querySelectorAll('.is--loading').forEach(element => {
        element.classList.remove('is--loading');
      });
    }
  }

  /**
 * set cookie details
 *
 * @param {String} cname Cooke name
 * @param {String} cvalue Cooke value
 * @param {Number} exdays Expiry Days Count
 */
  public static setCookie(cname, cvalue, exdays = 10) {
    localStorage.setItem(cname, cvalue);
  }

  /**
   * get cookie details by name
   *
   * @param {String} cname Cooke name
   * @returns {String} as cookie details
   */
  public static getCookie(cname) {
    const storage = localStorage.getItem(cname) ? localStorage.getItem(cname) : null;
    return storage
  }
  /**
   * delete cookie by name
   *
   * @param {String} cname Cooke name
   */
  public static deleteCookie(cname) {
    localStorage.removeItem(cname);
  }

  /**
   * handle http errors
   *
   * @param {Object} errorObj HTTP Error Object
   */
  public static handleHttpError(errorObj) {
    let ErrorObj = {};
    let ErrorList = [];
    let Message;
    let Status;
    let Icon;
    let ExtraClass = "";
    
    if (errorObj.status === 200) { // Success With Message
      Message = errorObj.error && errorObj.error.text ? errorObj.error.text : "Action has been successfully performed!";
      Status = "success";
      Icon = "fa fa-check-circle";  
    } else { // Error
      Message = errorObj.error && errorObj.error.message ? errorObj.error.message : "Something went wrong!";
      Status = "danger";
      Icon = "fa fa-warning";  
    }
    
    AppHelpers.hideLoader();
    return  EMPTY;
  };
}
