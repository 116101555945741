import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable ,  throwError, EMPTY, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { catchError } from 'rxjs/operators';
import { AppHelpers } from '../utils/helper';
import { element } from 'protractor';
import { Router } from '@angular/router';
import { NgxNotificationService } from 'ngx-notification';

let self;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  helperClass;
  constructor(
    private http: HttpClient,
    private router: Router,
    private _helper: AppHelpers,
    private ngxNotificationService: NgxNotificationService
  ) {
    this.helperClass = this._helper;
    self = this;
  }

  formatErrors(errorObj: any) {
    console.log("ERROOORRRR", errorObj);
    let message = "There was some unknown error";
    if (errorObj && errorObj.error) {
      message = errorObj.error.message;
    }
    self.ngxNotificationService.sendMessage(message, 'danger', 'bottom-left');
    AppHelpers.hideLoader();
    return  EMPTY;
  }

  get(path: string, params: HttpParams = new HttpParams(), headers = {}, checkLogin = true, handleInternalError = true, apiURL = null): Observable<any> {
    let sendRequest = false;
    // Decides to sendRequest or not
    if (checkLogin) {
      sendRequest = AppHelpers.isLoggedIn() ? true : false;
    } else {
      sendRequest = true;
    }

    let fullURL = `${environment.api_url}${path}`;
    if (path.includes('http')) {
      fullURL = path;
    }

    // then sends it..
    if (sendRequest) {
      if (handleInternalError) {
        return this.http.get(`${apiURL || fullURL}`, { params, headers }).pipe(catchError(this.formatErrors));
      } else {
        return this.http.get(`${apiURL || fullURL}`, { params, headers });
      }
      
    } else {
      this.router.navigate(['/login']);
    }
    
  }

  put(path: string, body: Object = {}, headers = {}, checkLogin = true): Observable<any> {
    let sendRequest = false;
    // Decides to sendRequest or not
    if (checkLogin) {
      sendRequest = AppHelpers.isLoggedIn() ? true : false;
    } else {
      sendRequest = true;
    }

    // then sends it..
    if (sendRequest) {
      return this.http.put(`${environment.api_url}${path}`, body, { headers }).pipe(catchError(this.formatErrors));
    } else {
      this.router.navigate(['/login']);
    }
  }

  post(path: string, body: Object = {}, headers = {}, checkLogin = true, handleInternalError = true): Observable<any> {
    let sendRequest = false;
    // Decides to sendRequest or not
    if (checkLogin) {
      sendRequest = AppHelpers.isLoggedIn() ? true : false;
    } else {
      sendRequest = true;
    }

    // then sends it..
    if (sendRequest) {
      if (handleInternalError) {
        return this.http.post(`${environment.api_url}${path}`, body, { headers }).pipe(catchError(this.formatErrors));
      } else {
        return this.http.post(`${environment.api_url}${path}`, body, { headers });
      }
      
    } else {
      this.router.navigate(['/login']);
    }
  }

  delete(path, headers = {}, checkLogin = true): Observable<any> {
    let sendRequest = false;
    // Decides to sendRequest or not
    if (checkLogin) {
      sendRequest = AppHelpers.isLoggedIn() ? true : false;
    } else {
      sendRequest = true;
    }

    // then sends it..
    if (sendRequest) {
      return this.http.delete(`${environment.api_url}${path}`, { headers }).pipe(catchError(this.formatErrors));
    } else {
      this.router.navigate(['/login']);
    }
  }
}
