import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { NgxNotificationService } from "ngx-notification";
import { ApiService } from "src/app/services/api.service";
import { AppHelpers } from "src/app/utils/helper";

@Component({
  selector: "app-register",
  templateUrl: "register.component.html"
})
export class RegisterComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  displayPage = false;
  focus;
  focus1;
  focus2;
  registerButton = 'registerButton';
  registerError = false;
  inputFocus: any = {};
  requestBody: any = {
    userType: 1,
  };
  countriesList = [];
  constructor(private _router: Router, private api: ApiService, private ngxNotificationService: NgxNotificationService) {
    this.getAllCountries();

    if (window.prompt("Please enter the password") === "Wormy12345!") {
      this.displayPage = true;
    }
  }

  getAllCountries () {
    this.api.get('http://api.countrylayer.com/v2/all?access_key=6204786a7f80421612faec5814d3154f', null, {}, false).subscribe(res => {
      this.countriesList = res;
    })
  }
  
  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("register-page");
  }

  submitRegister() {
    if (this.requestBody['name'] && this.requestBody['email'] && this.requestBody['username'] && this.requestBody['password'] === this.requestBody['confirmPassword']) {
      AppHelpers.showLoader(this.registerButton);
      this.api.post('/api/user/register', this.requestBody, {}, false).subscribe((res) => {
        if (res.data) {
          this.ngxNotificationService.sendMessage('User successfully Registered. A verification email has been sent to you.', 'success', 'bottom-left');
          
          AppHelpers.hideLoader(this.registerButton);
          this._router.navigate(['/login']);
        }
      })
    } else {
      this.ngxNotificationService.sendMessage('Form must be validated in order to send request.', 'warning', 'bottom-left');
    }
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("register-page");
  }
}
