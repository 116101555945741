import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { AppHelpers } from "src/app/utils/helper";

@Component({
  selector: "app-splash",
  templateUrl: "home.component.html"
})
export class SplashComponent implements OnInit, OnDestroy {
  consoleLog = console.log;
  // iframeUrl = `https://www.youtube.com/embed/_dnZpc-Ghr8?autoplay=1&controls=0&loop=1&origin=${encodeURIComponent(location.origin)}&playsinline=1&widgetid=1`;
  iframeUrl = `https://www.youtube.com/embed/_dnZpc-Ghr8?autoplay=1&controls=0&loop=1&playsinline=1&widgetid=1`;
  constructor(private _router: Router) {}
  
  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("splash-page");
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("splash-page");
  }

  clearSessionAndRedirect() {
    AppHelpers.destroyUserInfo();
    AppHelpers.destroyToken();
    this._router.navigate(['login']);
  }
}
