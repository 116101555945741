import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import noUiSlider from "nouislider";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "privacy-policy.component.html"
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
  constructor() {}
  ngOnInit() {
    
  }
  ngOnDestroy() {
  }
}