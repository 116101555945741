import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";


import { PagesModule } from "./pages/pages.module";

import {
  CommonModule
} from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import {
  NgxNotificationComponent,
  NgxNotificationModule,
  NgxNotificationService,
} from "ngx-notification";
import { AuthGuard } from "./guards/auth-guard.service";
import { AuthenticatedGuard } from "./guards/authenticated-guard.service";
import { ApiService } from "./services/api.service";
import { AppHelpers } from "./utils/helper";

@NgModule({
  declarations: [
    AppComponent,
    // IndexComponent,
    // ProfilepageComponent,
    // RegisterpageComponent,
    // LandingpageComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    // BsDropdownModule.forRoot(),
    // ProgressbarModule.forRoot(),
    // TooltipModule.forRoot(),
    // CollapseModule.forRoot(),
    // TabsModule.forRoot(),
    PagesModule,
    NgxNotificationModule,
    // PaginationModule.forRoot(),
    // AlertModule.forRoot(),
    // BsDatepickerModule.forRoot(),
    // CarouselModule.forRoot(),
    // ModalModule.forRoot()
  ],
  exports: [NgxNotificationModule],
  providers: [
    ApiService,
    AppHelpers,
    NgxNotificationService,
    AuthGuard,
    AuthenticatedGuard,
    NgxNotificationComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
