import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NgxNotificationService } from "ngx-notification";
import noUiSlider from "nouislider";
import { ApiService } from "src/app/services/api.service";
import { AppHelpers } from "src/app/utils/helper";

@Component({
  selector: "app-stream",
  templateUrl: "stream.component.html"
})
export class StreamComponent implements OnInit, OnDestroy {
  @ViewChild('myModal2') myModal2;
  @ViewChild('termsModal') termsModal;
  isCollapsed = true;
  focus;
  focus1;
  focus2;
  streamButton = 'streamButton';
  streams = [];
  header;
  packagesList = [];
  userImage: any = "https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg";
  requestBody: any = {
    packageId: '613dc7778c20abe22b91d334',
    pictureUrl: "https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg",
    imageFile: null,
  }
  constructor(private _router: Router, private sanitizer: DomSanitizer, private ngxNotificationService: NgxNotificationService, private api: ApiService) {
    this.header = new HttpHeaders({
      "x-access-token": AppHelpers.getToken()
     });

    this.getPackagesData();

    if (localStorage.getItem('broadcasterStream') && JSON.parse(localStorage.getItem('broadcasterStream'))) {
      this._router.navigate(['/play-stream']);
    }
  }

  scrollToDownload(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }
  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("index-page");
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("index-page");
  }

  getPackagesData () {
    this.api.get('/api/package/listener', null, this.header, true).subscribe((res) => {
      if (res.data) {
        this.packagesList = res.data;
      }
    })
  }

  createStream() {
    if (this.requestBody['name'] && this.requestBody['packageId']) {
      AppHelpers.showLoader(this.streamButton);
      console.log(this.requestBody);
      if (this.requestBody.imageFile) {
        this.uploadImage().then(() => {
          this.sendStreamAddRequest()
        }).catch(() => {
          this.ngxNotificationService.sendMessage("Image couldn't be uploaded", 'warning', 'bottom-left');
        })
      } else {
        this.sendStreamAddRequest()
      }
    } else {
      this.ngxNotificationService.sendMessage('Make sure to select package and name your stream', 'warning', 'bottom-left');
    }
  }

  setFile(event) {
    this.requestBody.imageFile = event.target.files[0];
    
    const file = this.requestBody.imageFile;
    const fileURL = URL.createObjectURL(file);
    const sanitizeURL =  this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
    this.userImage = sanitizeURL;
  }

  sendStreamAddRequest() {
    this.api.post('/api/stream/add', this.requestBody, this.header, true).subscribe((res) => {
      if (res.data) {
        this.ngxNotificationService.sendMessage('Stream succefully added!', 'success', 'bottom-left');
        AppHelpers.hideLoader(this.streamButton);
        
        this.api.get('/api/stream/broadcasterStream', null, this.header, false).subscribe(response => {
          if (response.success && response.data != null) {
            this.myModal2.hide();
            localStorage.setItem('broadcasterStream', JSON.stringify(response.data));
            this._router.navigate(['/play-stream']);
            this.ngxNotificationService.sendMessage('Broadcast successfully added!', 'success', 'bottom-left');
          } 
        })
      }
    })
  }

  uploadImage() {
    return new Promise<void>((resolve, reject) => {
      const file = this.requestBody.imageFile;

      const formData = new FormData();
      formData.append('file', file);

      this.api.post('/api/stream/upload', formData, this.header, true).subscribe((res) => {
        
        this.requestBody.pictureUrl = res.data;
        this.userImage = res.data;
        
        this.ngxNotificationService.sendMessage(res.message, 'success', 'bottom-left');
        
        resolve(res.data);
      }, (err) => {
        this.ngxNotificationService.sendMessage(err.message, 'warning', 'bottom-left');
        reject(err);
      })
    })
  }

  openCreate() {
    this.termsModal.hide();
    this.myModal2.show();
  }
}
